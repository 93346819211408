export const shadows = [
  "none", // index 0
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
  "0px 0px 16px rgba(16, 24, 40, 0.1)",
];
