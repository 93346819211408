export const light = {
  primary: {
    main: "#006766",
    danger: "red",
  },
  secondary: {
    main: "#11cb5f",
  },
  divider: "#D0D5DD",
};
